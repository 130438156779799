import React from "react";
import { useTranslation } from "react-i18next";


const VacancyPage = ({ img, title, description, lookingForTitle, lookingForArr, offerTitle, offerArr }) => {
	const LiMaker = ({title, description, type}) => (
		<div className={`vacancy-li ${type}-container`}>
			<div className={`${type}-title intro-title`}>{title}</div>
			<ul className={`${type}-description vacancy-description`}>
				{description.map((item, index) => (
					<li key={`${type}-${index}`}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);

    const { t } = useTranslation();
	return (
        <>
            <div className={"container vacancy-wrapper"}>
                <div className="vacancy-info">
                    <div className="vacancy-title intro-title">{title}</div>
                    <div className="vacancy-description">{description}</div>
                    <LiMaker
                        title={lookingForTitle}
                        description={lookingForArr}
                        type={"lookingFor"}
                    />
                    <LiMaker
                        title={offerTitle}
                        description={offerArr}
                        type={"offer"}
                    />
                </div>
                <div className="vacancy-img">
                    <img src={img} alt={img} />
                </div>
            </div>
            <div className="vacancies-page-footer">
                <p className="vacancies-page-footer-text">
                    {t(`vacancy.response-start`)}
                    <a
                        href="mailto:job@growire.org"
                        style={{ color: "#005fd2" }}
                    >
                        job@growire.org
                    </a>
                    {t(`vacancy.response-end`)}
                </p>
            </div>
			{/*<button className={"vacancy-respond"}>Откликнуться</button>*/}
        </>
    );
};

export default VacancyPage;
